
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import { getScript } from '@/utils/utils'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'CiticBankLogin',
  mixins: [GlobalMixin]
})
export default class CiticBankLoginPage extends Vue {
  @Provide() public isChecked: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() public accessCode: string = ''
  @Provide() public submitting: boolean = false

  @Emit()
  public checkAgreement () {
    this.isChecked = !this.isChecked
  }

  @Emit()
  public async next () {
    if (!this.isChecked) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    const data: any = {
      authCode: this.accessCode
    }
    this.doAuth()
  }

  public async doAuth () {
    if (this.submitting) {
      return
    }
    const data: any = {
      authCode: this.accessCode
    }
    try {
      this.submitting = true
      const zijinrcb = await httpHelper.getAuthLogin(data, 'authorize/ecitic')
      location.replace(decodeURIComponent(getCurrentQuery('redirect')))
    } catch (err) {
      this.goTo(err, 9, 9)
      this.$toasted.show(err.msg)
    } finally {
      this.submitting = false
    }
  }

  public async created () {
    const accessCode = getCurrentQuery('accessCode')
    if (!accessCode) {
      this.redirectOAuth()
      return
    }
    this.accessCode = accessCode
    if (getMBankAuth()) {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }
  public async redirectOAuth () {
    try {
      const res = await httpHelper.get({
        url: `ThirdLogin/Sdk`,
        data: {
          identity: 'http://Ecitic39.benlai.com'
        }
      })
      console.log('ThirdLogin/Sdk', res)
      const { oauth, appId } = res.value.expand
      location.href = `${oauth}?appid=${appId}&scope=queryUserInfo&returnUrl=${encodeURIComponent(location.href)}`
    } catch (err) {
      if (!err) {
        return
      }
      this.$toasted.show(err.msg)
    }
  }
}
