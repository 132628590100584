
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'
import AddressItem from '@/components/addressItem.vue'
import index from '@/store'
import GlobalMixin from '@/mixins/global'
import { setSelectedHouseAddress, removeSelectedHouseAddress } from '@/utils/cookies'

@Component({
  name: 'homeDeliveryAddress',
  mixins: [GlobalMixin]
})
export default class homeDeliveryActivation extends Vue {
  @Provide() public showSelectNolikeFood: boolean = false
  @Provide() public loading: boolean = true
  @Provide() private orderSysNo: string = ''
  @Provide() private sysNo: number = 0
  @Provide() private modifyType: any = '' // 宅配详情修改地址的类型，all指修改所有，single指修改单次
  @Provide() public canUseAddress: any = []
  @Provide() public canNoUseAddress: any = []
  private startX: number = 0
  private startY: number = 0
  private extend: number = 0

  @Emit()
  private created () {
    setSelectedHouseAddress({})
    this.currentRouteChanged()
    this.refresh()
  }

  @Watch('$route')
  private currentRouteChanged () {
    const { oid } = this.$route.params
    const { type } = this.$route.query
    this.orderSysNo = oid || ''
    this.modifyType = type || ''
    setSelectedHouseAddress({
      type: this.modifyType
    })
  }

  @Emit()
  private async refresh () {
    this.loading = true
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Address',
        data: {
          giftCardSysNo: this.orderSysNo
        },
        type: 'apiv5'
      })
      const result = res.data || {}
      this.canUseAddress = result.canUse || []
      this.canNoUseAddress = result.canNotUse || []
    } catch (err) {
      this.$toasted.show(err.msg)
    } finally {
      this.loading = false
    }
  }

  @Emit()
  private async selectAddress (address: any) {
    setSelectedHouseAddress({
      type: this.modifyType,
      sysNo: address.sysNo,
      contact: address.contact,
      phone: address.phone,
      address: address.fullAddress
    })
    this.$router.go(-1)
  }

  @Emit()
  private async setDefaultAddress (sysNo: number) {
    try {
      await httpHelper.post({
        url: 'IUserHome/SetDefaultAddress',
        data: {
          sysNo: sysNo
        },
        type: 'apiv5'
      })
      this.refresh()
    } catch (err) {
      console.log(err)
    }
  }

  @Emit()
  private touchStart (e: any) {
    this.startX = e.touches[0].clientX
    this.startY = e.touches[0].clientY
  }

  @Emit()
  private touchEnd (e: any, sysNo: number) {
    const clientX = e.changedTouches[0].clientX
    const clientY = e.changedTouches[0].clientY
    const startX = this.startX
    const startY = this.startY
    const offsetX = clientX - startX
    const offsetY = clientY - startY
    this.startX = 0
    this.startY = 0

    if (startX === 0) {
      return
    }

    if (!this.isAngleLeft(offsetX, offsetY)) {
      return
    }

    if (offsetX <= -40) {
      this.extend = sysNo
    } else if (offsetX >= 40) {
      this.extend = 0
    }
  }

  @Emit()
  private isAngleLeft (dx: number, dy: number) {
    const x = Math.abs(dx)
    const y = Math.abs(dy)
    return !(x < 5 || (x >= 5 && y >= x * 1.73))
  }
}
