
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

const PAGE_SIZE = 20
const scale: number = window.document.documentElement.clientWidth / 375

@Component({
  name: 'homeDeliveryList',
  mixins: [GlobalMixin]
})
export default class HomeDeliveryList extends Vue {
  @Provide() public list: any = []
  @Provide() private cardPwd: string = ''
  private loadMore: boolean = true
  private pageIndex: number = 0
  private showBottom: boolean = false
  private status: number = 0
  private rule: any = {}
  private ruleShow: boolean = false
  private bindCardShow: boolean = false

  get empty () {
    return this.pageIndex === 0 && this.list && this.list.length === 0
  }

  get noText () {
    if (this.status === 0) {
      return '您尚没有宅配商品'
    }
    if (this.status === 1) {
      return '您没有待激活的宅配'
    }
    if (this.status === 2) {
      return '您没有已激活的宅配'
    }
    if (this.status === 3) {
      return '您没有已用完的宅配'
    }
  }

  @Watch('$route')
  private currentRouteChanged () {
    const { status } = this.$route.query
    this.status = status ? Number(status) : 0
  }

  public created () {
    this.currentRouteChanged()
    this.refresh()
  }

  private mounted () {
    window.addEventListener('scroll', this.fetchScrollTop)
  }

  private refresh () {
    this.pageIndex = 0
    this.list = []
    this.loadMore = true
    this.loadList()
  }

  @Emit()
  private async loadList () {
    if (!this.loadMore) {
      return
    }
    this.loadMore = false
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Cards',
        data: {
          offset: this.pageIndex,
          limit: PAGE_SIZE,
          status: this.status
        },
        type: 'apiv5'
      })
      console.log(res)
      const result = res.data || []
      this.list = [
        ...this.list,
        ...result
      ]
      this.loadMore = !!result.length
      this.showBottom = result.length < PAGE_SIZE
      if (this.loadMore) {
        this.pageIndex = result.length
      }
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private fetchScrollTop (event: any) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    const cHeight = document.documentElement.clientHeight || document.body.clientHeight
    const sHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    if (sHeight - (scrollTop + cHeight) < scale * 100) {
      this.loadList()
    }
  }

  public homeListTab (event:any) {
    this.status = parseInt(event)
    this.refresh()
    this.$router.replace({
      path: `/house/list`,
      query: {
        ...this.$router.currentRoute.query,
        status: event
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  public homeListRule (event:any) {
    this.rule = event
    this.ruleShow = true
  }

  public ruleClose () {
    this.ruleShow = false
  }

  public goDetail (event:any) {
    const SysNo = parseInt(event)
    const url = encodeURI(encodeURI(`/house/detail/` + SysNo))
    // location.replace(url)
    this.$router.push(url)
  }

  public goActivate (event:any) {
    const SysNo = parseInt(event)
    this.$router.push(`/house/activation/${SysNo}`)
    // location.replace(`${httpHelper.mRoot}homeDelivery/myCartActivate?activiteSysNo=` + SysNo)
  }

  public goProductDetail (event:any) {
    const SysNo = parseInt(event)
    location.href = `${httpHelper.mRoot}product/0.html?sysno=` + SysNo
  }

  public bindCard () {
    this.bindCardShow = true
  }

  public bindCardClose () {
    this.cardPwd = ''
    this.bindCardShow = false
  }

  @Emit()
  private bindCardPost () {
    const cardPwd = this.cardPwd.trim()
    if (cardPwd === '') {
      this.$toasted.show('请输入密码！')
      return
    }
    this.promotionsBindGiftCard(cardPwd)
    this.bindCardShow = false
  }

  @Emit()
  private async promotionsBindGiftCard (cardPwd:any) {
    try {
      const res = await httpHelper.post({
        url: 'IGift',
        data: {
          Pwd: this.cardPwd
        },
        type: 'apiv5'
      })
      console.log(res)
      // this.toActivate(res)
      if (res.error === '0') {
        this.toActivate(res)
      }
    } catch (err) {
      if (err !== 0) {
        this.promotionsBindGiftCardErr(err)
        this.cardPwd = ''
      }
    }
  }

  @Emit()
  private promotionsBindGiftCardErr (err:any) {
    let message = {
      title: '温馨提示',
      body: err.message
    }
    let options = {
      html: true,
      okText: '关闭'
    }
    this.$dialog.alert(message, options)
  }

  @Emit()
  private toActivate (res:any) {
    let activiteSysNo = res.data.sysNo
    let message = {
      title: '绑卡成功',
      body: '已绑定成功,请激活'
    }
    let options = {
      html: true,
      okText: '马上激活',
      cancelText: '下次再说'
    }
    this.$dialog.confirm(message, options)
      .then(() => {
        this.$router.push(`/house/activation/${activiteSysNo}`)
        // location.replace(`${httpHelper.mRoot}homeDelivery/myCartActivate?activiteSysNo=` + activiteSysNo)
      })
      .catch(() => {
        this.refresh()
      })
  }
}
