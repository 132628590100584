
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'
import AddressItem from '@/components/addressItem.vue'
import index from '@/store'
import GlobalMixin from '@/mixins/global'
import { setSelectedHouseAddress, getSelectedHouseAddress } from '@/utils/cookies'

@Component({
  name: 'homeDeliveryDetail',
  mixins: [GlobalMixin]
})
export default class HomeDeliveryDetail extends Vue {
  @Provide() private sysNo: string = ''
  @Provide() public cardInfo: any = {}
  @Provide() public deliveryInfo: any = {} // 总配送信息
  @Provide() public singleDeliveryInfo: any = {} // 修改单次配送的配送信息
  @Provide() public orderInfo: any = {}
  @Provide() public optionInfo: any = {} // 修改单次配送数据
  @Provide() public optionsDays: any = [] // 修改所有配送数据
  @Provide() public cardDetailsDish: any = {} // 选中的不喜欢的菜name
  @Provide() public footList: any = [] // 不喜欢的菜
  @Provide() public selectNoLikeVegetables: any = [] // 选中的不喜欢的蔬菜sysno列表
  private optionFootShow: boolean = false
  private onceModifyShow: boolean = false
  private modifyDeliveredShow: boolean = false
  private allModifyDeliveredShow: boolean = false
  private optionValue: number = -1 // 修改单次/所有配送的配送时间的value
  private loookMore: boolean = true
  @Provide() public currentModifyAddress: any = {} // 当前修改的地址
  @Provide() public addressType: any = 'all' // 当前修改的地址的类型，all修改全部配送，single选择单次配送

  @Watch('$route')
  private currentRouteChanged () {
    const { id } = this.$route.params
    const { type } = this.$route.query
    this.sysNo = id || ''
    this.addressType = type || 'all'
  }

  @Emit()
  private created () {
    this.currentRouteChanged()
    this.refresh()
  }

  @Emit()
  private activated () {
    this.currentRouteChanged()
    this.refresh()
    const selectAddress: any = getSelectedHouseAddress() ? JSON.parse(getSelectedHouseAddress()) : {}
    console.log(selectAddress)
    // 去除缓存中选中的地址后，把缓存置空
    setSelectedHouseAddress({})
    if (selectAddress.address) {
      this.currentModifyAddress = selectAddress || {}
    } else {
      this.currentModifyAddress = this.addressType === 'all' ? this.deliveryInfo : this.optionInfo.address
    }
    this.modifyDeliveredShow = selectAddress.type === 'single'
    this.allModifyDeliveredShow = selectAddress.type === 'all'
  }

  @Emit()
  private refresh () {
    this.loadCardDetails()
  }

  @Emit()
  public async loadCardDetails () { // 加载宅配卡信息
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Card',
        data: {
          hdCardSysNo: this.sysNo
        },
        type: 'apiv5'
      })
      this.cardInfo = res.data || {}
      this.deliveryInfo = this.cardInfo.address || {}
      this.orderInfo = this.cardInfo.orders || {}
      this.cardDetailsDish = this.cardInfo.dislikeVegetable || {}
      this.optionsDays = this.cardInfo.optionDays || []
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private optionModify (list:any) { // 修改单次配送信息
    this.optionInfo = list
    console.log(list)
    // this.singleDeliveryInfo = this.optionInfo.address
    this.currentModifyAddress = this.optionInfo.address
    list.optionDays.forEach((day: any) => { // 确认修改配送信息时把optionValue置成初始状态
      if (day.selected) {
        this.optionValue = day.value
      }
    })
    const detailSysNo = this.sysNo
    const sysNo = list.soSysNo
    if (list.status === 2) {
      const url = window.location.href.indexOf('pre-') > -1 ? 'https://pre-mc.benlai.com' : 'https://mc.benlai.com'
      location.href = `${url}/order/detail?sysNo=${sysNo}&soType=1&from=house`
    } else if (list.status === 1) {
      this.onceModifyShow = true
    }
  }

  @Emit()
  private modifyDelivered () { // 确认单次修改信息弹窗，确认修改配送信息
    this.onceModifyShow = false
    this.modifyDeliveredShow = true
  }

  @Emit()
  private async notDelivered () { // 推迟配送信息
    try {
      const res = await httpHelper.post({
        url: 'PeriodicOrder/Postpone',
        data: {
          planSysNo: this.optionInfo.sysNo,
          hdCardSysNo: this.sysNo
        },
        type: 'apiv5'
      })
      this.onceModifyShow = false
      this.refresh()
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  private modifyDeliveredSelect (index:number) { // 修改单次配送信息，配送时间选择
    const optionDays = this.optionInfo.optionDays
    optionDays.forEach((item: any, idx: number) => {
      item.selected = (idx === index)
    })
    this.optionValue = optionDays[index].value
  }

  @Emit()
  private giveUpModifyDeliver () {
    this.currentModifyAddress = this.deliveryInfo // 如果点击返回，当前选择的地址依然是接口返回的地址
    this.modifyDeliveredShow = false
    this.refresh()
  }

  @Emit()
  private giveUpAllModifyDeliver () {
    this.currentModifyAddress = this.deliveryInfo // 如果点击返回，当前选择的地址依然是接口返回的地址
    this.allModifyDeliveredShow = false
  }

  private allModifyDeliveredSelect (index:number) { // 修改所有配送信息，配送时间选择
    this.optionsDays.forEach((item: any, idx: number) => {
      item.selected = (idx === index)
    })
    this.optionValue = this.optionsDays[index].value
  }

  @Emit()
  private goAddresses () { // 选择其他地址
    this.$router.push(`/house/address/${this.sysNo}`)
  }

  @Emit()
  private async modifyDeliveredSave (type: number) { // 确认修改单次或多次配送信息
    let parmas = {}
    if (type === 1) {
      parmas = {
        planSysNo: this.optionInfo.sysNo,
        addressSysNo: this.currentModifyAddress.sysNo,
        deliveryDay: this.optionValue,
        hdCardSysNo: this.sysNo
      }
    } else {
      parmas = {
        planSysNo: 0,
        addressSysNo: this.currentModifyAddress.sysNo,
        deliveryDay: this.optionValue,
        hdCardSysNo: this.sysNo
      }
    }
    try {
      const res = await httpHelper.put({
        url: 'PeriodicOrder/Delivery',
        data: parmas,
        type: 'apiv5'
      })
      if (type === 1) {
        this.modifyDeliveredShow = false
      } else {
        this.allModifyDeliveredShow = false
      }
      this.refresh()
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  @Emit()
  private async optionFoot (event:any) { // 去选择不喜欢的菜
    this.optionFootShow = true
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Vegetables', // 加载配送蔬菜类目
        data: {
          giftCardSysNo: this.cardInfo.giftCardSysNo
        },
        type: 'apiv5'
      })
      const result = res.data || []
      const dishs: any[] = this.cardDetailsDish.categories || []
      result.forEach((item: any) => {
        let selects = dishs.includes(item.name)
        if (dishs.includes(item.name)) {
          item.select = true
          this.selectNoLikeVegetables.push(item.sysNo)
        }
      })
      this.footList = result || []
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private optionFootSelect (index:number) { // 选择不喜欢的菜
    let vegetables = this.footList || []
    const categories = this.cardDetailsDish.categories || []
    this.footList = []
    vegetables[index].select = !vegetables[index].select
    vegetables[index].currentSelect = !vegetables[index].currentSelect // 添加当前选择的标识
    this.footList = vegetables
  }

  @Emit()
  private giveUpSelect () { // 点击返回放弃修改
    const vegetables = this.footList || []
    let categories = this.cardDetailsDish.categories || []
    categories = []
    vegetables.forEach((item: any) => {
      item.select = item.currentSelect ? (!item.select) : item.select // 如果是当前选中的select状态变回初始状态
      if (item.select) {
        this.selectNoLikeVegetables.push(item.sysNo)
        categories.push(item.name)
      }
      item.currentSelect = false
    })
    this.footList = []
    this.footList = vegetables
    this.cardDetailsDish.categories = categories
    this.optionFootShow = false
  }

  @Emit()
  private async saveFood () { // 确认修改不喜欢的菜
    const vegetables = this.footList || []
    this.cardDetailsDish.categories = []
    this.selectNoLikeVegetables = []
    vegetables.forEach((item: any) => {
      if (item.select) {
        this.cardDetailsDish.categories.push(item.name)
        this.selectNoLikeVegetables.push(item.sysNo)
      }
      item.currentSelect = false
    })
    try {
      const res = await httpHelper.post({
        url: 'PeriodicOrder/Vegetables',
        data: {
          categorySysNos: this.selectNoLikeVegetables,
          hdCardSysNo: this.cardInfo.hdCardSysNo,
          giftCardSysNo: this.cardInfo.giftCardSysNo
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show(res.data)
      }
      this.optionFootShow = false
      this.refresh()
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  @Emit()
  private async allModifyDelivered () { // 修改所有配送
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/CheckDelivery', // 检查卡默认配送信息与周期订单的配送信息是否一致
        data: {
          hdCardSysNo: this.sysNo
        },
        type: 'apiv5'
      })
      this.optionsDays.forEach((option: any) => { // 确认修改配送信息时把optionValue置成初始状态
        if (option.selected) {
          this.optionValue = option.value
        }
      })
      this.currentModifyAddress = this.deliveryInfo
      this.allModifyDeliveredShow = true
    } catch (err) {
      if (err.error === '1' || err.error === '-1') {
        this.allModifyDeliveredErr(err)
      }
    }
  }

  @Emit()
  private allModifyDeliveredErr (err:any) {
    let message = {
      body: err.message
    }
    let options = {
      html: true,
      okText: '确定',
      cancelText: '取消'
    }
    this.$dialog.confirm(message, options)
      .then(() => {
        this.optionsDays.forEach((option: any) => { // 确认修改配送信息时把optionValue置成初始状态
          if (option.selected) {
            this.optionValue = option.value
          }
        })
        this.currentModifyAddress = this.deliveryInfo
        this.allModifyDeliveredShow = true
      })
      .catch(() => {})
  }

  public goProductDetail () {
    const SysNo = this.cardInfo.productSysNo
    location.replace(`${httpHelper.mRoot}product/0.html?sysno=` + SysNo)
  }
}
