
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { setMeicanAuth, getMeicanAuth } from '@/utils/cookies'

@Component({
  name: 'CiticLife',
  mixins: [GlobalMixin]
})
export default class CiticLifePage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  @Provide() public code: string = ''
  // @Provide() public token: string = ''
  // @Provide() public sign: string = ''
  // @Provide() public t: number = 0

  async created () {
    this.code = getCurrentQuery('citicbank_token') || getCurrentQuery('code')
    // this.token = getCurrentQuery('userId')
    // this.sign = getCurrentQuery('sign')
    // this.t = parseInt(getCurrentQuery('t'))
    if (getMeicanAuth()) {
      this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMeicanAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const data: any = {
      authCode: this.code
    }
    // debugger
    try {
      const res = await httpHelper.post({
        url: 'authorize/citicWelfare',
        contentType: 'application/x-www-form-urlencoded',
        data: data,
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      console.log(res.value.extend.gotoUrl)
      location.replace(res.value.extend.gotoUrl)
    } catch (err) {
      this.goTo(err, 9, 105)
      this.$toasted.show(err.msg || err.message)
    }
  }
}
