
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import AddressModule from '@/store/modules/address'
import httpHelper from '@/utils/request'
import AddressItem from '@/components/addressItem.vue'
import index from '@/store'
import GlobalMixin from '@/mixins/global'
import { setSelectedHouseAddress, getSelectedHouseAddress } from '@/utils/cookies'

@Component({
  name: 'homeDeliveryActivation',
  mixins: [GlobalMixin]
})
export default class homeDeliveryActivation extends Vue {
  @Provide() public showSelectNolikeFood: boolean = false
  @Provide() public sysNo: string = ''
  @Provide() public cardInfo: any = {}
  @Provide() public optionDays: any = []
  @Provide() public selectOptionValue: number = 0 // 选中的配送时间的value
  @Provide() public address: any = {}
  @Provide() public noLikeVegetables: any = []
  @Provide() public selectNoLikeVegetables: any = [] // 选中的不喜欢的蔬菜sysno列表
  @Provide() public selectNoLikeVegetableNames: any = [] // 选中的不喜欢的蔬菜菜名列表
  @Provide() public titleText: string = '激活宅配'

  @Watch('$route')
  private currentRouteChanged () {
    const { id } = this.$route.params
    this.sysNo = id
  }

  @Emit()
  private created () {
    this.address = getSelectedHouseAddress() ? JSON.parse(getSelectedHouseAddress()) : {}
    // 去除缓存中选中的地址后，把缓存置空
    setSelectedHouseAddress({})
    this.currentRouteChanged()
    this.refresh()
    this.loadNoLikeVegetables()
  }

  @Emit()
  private async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Activate',
        data: {
          giftCardSysNo: this.sysNo
        },
        type: 'apiv5'
      })
      console.log(res)
      this.cardInfo = res.data || {}
      this.optionDays = this.cardInfo.optionDays || []
      let isSelect: boolean = false // 如果配送时间里没有被选中的，默认选中第一个
      this.optionDays.forEach((item: any) => {
        if (item.selected) {
          isSelect = item.selected
          this.selectOptionValue = item.value
        }
      })
      if (!isSelect) {
        this.optionDays[0].selected = true
        this.selectOptionValue = this.optionDays[0].value
      }
      console.log('selectOptionValue', this.selectOptionValue)
      // 如果缓存中没有选中的地址则显示接口返回的地址
      this.address = this.address.address ? this.address : this.cardInfo.address
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  @Emit()
  private async loadNoLikeVegetables () {
    try {
      const res = await httpHelper.get({
        url: 'PeriodicOrder/Vegetables',
        data: {
          giftCardSysNo: this.sysNo
        },
        type: 'apiv5'
      })
      console.log(res)
      this.noLikeVegetables = res.data || []
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  @Emit()
  private goAddress () {
    this.$router.push(`/house/address/${this.sysNo}`)
  }

  @Emit()
  private selectOption (index: number) {
    const optionDays = this.optionDays
    this.optionDays = []
    optionDays.forEach((item: any, idx: number) => {
      item.selected = (idx === index)
    })
    this.optionDays = optionDays
    this.selectOptionValue = this.optionDays[index].value
  }

  @Emit()
  private selectVegetables (index: number) {
    const vegetables = this.noLikeVegetables
    this.noLikeVegetables = []
    vegetables[index].select = !vegetables[index].select
    vegetables[index].currentSelect = !vegetables[index].currentSelect // 添加当前选择的标识
    this.noLikeVegetables = vegetables
  }

  @Emit()
  private confirmNolikeFood () {
    const vegetables = this.noLikeVegetables
    this.selectNoLikeVegetableNames = []
    this.selectNoLikeVegetables = []
    vegetables.forEach((item: any) => {
      if (item.select) {
        this.selectNoLikeVegetableNames.push(item.name)
        this.selectNoLikeVegetables.push(item.sysNo)
      }
      item.currentSelect = false
    })
    this.showSelectNolikeFood = false
  }

  @Emit()
  private async activityDelivery () {
    try {
      const res = await httpHelper.post({
        url: 'PeriodicOrder/Activate',
        data: {
          giftCardSysNo: this.sysNo,
          deliveryDay: this.selectOptionValue,
          categorySysNos: this.selectNoLikeVegetables,
          addressSysNo: this.address.sysNo
        },
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$router.push('/house/list?status=2')
      }
    } catch (err) {
      this.$toasted.show(err.message)
    }
  }

  @Emit()
  private goback () {
    if (this.showSelectNolikeFood) {
      const vegetables = this.noLikeVegetables
      this.selectNoLikeVegetableNames = []
      this.selectNoLikeVegetables = []
      vegetables.forEach((item: any) => {
        item.select = item.currentSelect ? (!item.select) : item.select // 如果是当前选中的select状态变回初始状态
        if (item.select) {
          this.selectNoLikeVegetables.push(item.sysNo)
          this.selectNoLikeVegetableNames.push(item.name)
        }
        item.currentSelect = false
      })
      this.noLikeVegetables = []
      this.noLikeVegetables = vegetables
      this.showSelectNolikeFood = false
    } else {
      let message = {
        title: '确认离开',
        body: '离开后,当前宅配设置将不会被保存'
      }
      let options = {
        html: true,
        okText: '确定',
        cancelText: '取消'
      }
      this.$dialog.confirm(message, options)
        .then(() => {
          this.back()
        })
        .catch(() => {})
    }
  }
}
