
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

const PAGE_SIZE = 20
const scale: number = window.document.documentElement.clientWidth / 375

@Component({
  name: 'userReview',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class userReview extends Vue {
  @Provide() public list: any = []
  @Provide() extend: number = 0
  @Provide() public isdialog: boolean = false
  @Provide() public commentSysNo: number = 0
  @Provide() public offset: number = 0
  @Provide() public over: boolean = false
  @Provide() public loading: boolean = false

  public created () {
    this.refresh()
  }

  private mounted () {
    window.addEventListener('scroll', this.fetchScrollTop)
  }

  @Emit()
  private async refresh () {
    this.list = []
    this.offset = 0
    this.loadmore()
  }

  @Emit()
  private async loadmore () {
    const self = this
    if (self.over || self.loading) {
      return
    }
    self.loading = true
    try {
      const res = await httpHelper.get({
        url: 'IUser/Comments',
        type: 'apiv5',
        data: {
          Offset: self.offset,
          Limit: PAGE_SIZE
        }
      })
      let list = res.data || []

      this.over = list.length === 0

      self.offset += PAGE_SIZE
      this.list = [
        ...this.list,
        ...list
      ]
    } catch (err) {
      this.showErrorToast(err)
    }
    self.loading = false
  }

  @Emit()
  public opendialog () {
    this.isdialog = true
  }

  @Emit()
  private async hideComment (commentSysNo: number) {
    const self = this
    try {
      const res = await httpHelper.delete({
        url: 'IUser/Comments',
        type: 'apiv5',
        contentType: 'application/x-www-form-urlencoded',
        data: { commentSysNo }
      })
      this.$toasted.show('隐藏成功')
      this.list = this.list.filter((comment: any) => comment.commentSysNo !== commentSysNo)
    } catch (err) {
      console.log(this)
      this.showErrorToast(err)
    }
  }

  @Emit()
  private fetchScrollTop (event: any) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    const cHeight = document.documentElement.clientHeight || document.body.clientHeight
    const sHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    if (sHeight - (scrollTop + cHeight) < scale * 100) {
      this.loadmore()
    }
  }

  @Emit()
  private goback () {
    history.back()
  }
}

