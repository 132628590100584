
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import { parseSearch } from '@/utils/utils'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { setArgument } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'Arugment',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  async created () {
    let { skip = '0' } = this.$route.query
    if (Number(skip) > 0) {
      this.select()
      this.next()
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setArgument()
    // const target: any = this.$route.query.target
    // const source: any = this.$route.query.source || '11'
    let { target, source = '11', ...params } = this.$route.query

    target = target.toString()
    const json = encodeURIComponent(JSON.stringify(params))
    if (!target) {
      location.replace(`/partner?redirect=https%3A%2F%2Fh5.benlai.com%2F&source=11&data=${json}`)
      return
    }
    if (/^http(s?):/.test(target)) {
      location.replace(`/partner?redirect=${encodeURIComponent(target)}&source=${source}&data=${json}`)
      return
    }
    switch (target) {
      case 'm':
        location.replace(`/partner?redirect=https%3A%2F%2Fm.benlai.com%2F&data=${json}`)
        return
      default:
        location.replace(`/partner?redirect=https%3A%2F%2Fh5.benlai.com%2F&source=11&data=${json}`)
    }
  }
}
