
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import { parseSearch } from '@/utils/utils'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { setArgument } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'YueJing',
  mixins: [GlobalMixin]
})
export default class YueJingPage extends Vue {
  async created () {
    const querystrings = parseSearch(location.search)
    let url = `/argument?target=https%3A%2F%2Fyjgh-h5.benlai.com%2F%3Ft%3D${new Date().getTime()}&source=11&`
    const str = Object.keys(querystrings).map(key => {
      return `${key}=${querystrings[key]}`
    }).join('&')
    url += str
    this.$router.replace(url)
  }
}
